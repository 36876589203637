<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <!-- <ngx-loader [show]="show" [fullScreen]="fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader> -->

            <!-- <h3 class="bs-title" >Add <span class="spanbs-title">Endorsement </span>
                <button type="button" class="btn btn-primary" role="button" style="float:right">Back</button>
            </h3> -->

            <h3 class="bs-title" *ngIf="addflag == 0">CD Updation 
                <button type="button" class="btn btn-primary" (click)="openPopupAdd()" role="button" style="float:right">+ Add</button>
            </h3>

            <div class="alert alert-success" id="validmsg"
                (click)="closealert()" style="display: none;">
                <strong>Success! {{ validmsg }} <span
                        class="alertspan">&times;</span></strong>
            </div>
            <div class="alert alert-danger" id="errormsg" (click)="closealert()"
                style="display: none;">
                <strong>Something went wrong. {{ validmsg }} <span
                        class="alertspan">&times;</span></strong>
            </div>
            <hr class="mb-2">

            <div class="row"  *ngIf="addflag == 0">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="client_name">Client Name</label>
                        <select class="form-select" id="client_name" (change)="getInfo($event, 'custom')">
                            <option value="" selected>Select Client Name</option>
                            <option *ngFor="let res of ClientId" [value]="res.id">{{ res.name }}</option>
                        </select>
                    </div>
                </div>

                <!-- <div class="col-sm-4">
                    <div class="form-group">
                        <label class="bs-form-label" for="client_name">Client Name</label>
                        <select class="form-select" id="client_name" (change)="getInfo($event, 'custom')">
                            <option value="" selected>Select Client Name</option>
                            <option *ngFor="let res of ClientId" [value]="res.id">{{ res.name }}</option>
                        </select>
                    </div>
                </div> -->
            </div>


                <!-- Listing -->
                <div class="mt-3" *ngIf="addflag == 0">
                    <table class="table table-sm row-border" width="100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                        <tr>
                            <th style="width:5%">SrNo</th>
                            <th>Upload Type</th>
                            <th>Policy Name</th>
                            <th>Policy No.</th>
                            <th>Transaction Type</th>
                            <th>Transaction Date</th>
                            <th>System Generated Endorsement No.</th>
                            <th>Opening CD Balance</th>
                            <th>Utilized Amount</th>
                            <th>Closing CD Balance</th>
                            <th>Insurer Endorsement Copy / Policy Copy</th>
                            <th>Uploaded At</th>
                            <!-- <th>No. of Members </th> -->
                            <!-- <th>Status </th> -->
                            <!-- <th style="text-align: center;">Action</th> -->
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let group of InfoArray; let i = index">
                            <td style="width:5%">{{i+1}}</td>
                            <td>{{group['upload_type']}}</td>
                            <td>{{group['policy_name']}}</td>
                            <td>{{group['policy_number']}}</td>
                            <td>{{group['transaction_type']}}</td>
                            <td>{{group['transaction_date']}}</td>
                            <td>{{group['system_generated_endorsement_number']}}</td>
                            <td>{{group['opening_cd_balance']}}</td>
                            <td>{{group['utilized_amount']}}</td>
                            <td>{{group['closing_cd_balance']}}</td>
                            <td *ngIf="group['insurer_endorsement_copy']==='' && group['insurer_endorsement_copy']===null"><span>NA</span></td>
                            <td style="width: 15%" align="center">
                                <img *ngIf="group['insurer_endorsement_copy']!=='' && group['insurer_endorsement_copy']!== null" src="../../../assets/img/common icons/downloaddoc.png" id="download" (click)="ondownload_uploaded(group['insurer_endorsement_copy'], group['insurer_endorsement_copy'])" class="ml-4" role="button" title="Download" />
                            </td>
                            <td>{{group['added_date']}}</td>
                            <!-- <td *ngIf="group['is_request_status']==1"><span _ngcontent-igh-c148="" class="btn btn-inverse-success btn-fw">Accepted</span></td>
                            <td *ngIf="group['is_request_status']==2"><span _ngcontent-igh-c148="" class="btn btn-inverse-danger btn-fw">Declined</span></td>
                            <td *ngIf="group['is_request_status']==0"><span _ngcontent-igh-c148="" class="btn btn-inverse-danger btn-fw">Pending</span></td> -->
                            <!-- <td style="width: 15%" align="center"> -->
                                <!-- <img  *ngIf="group['is_request_status'] === 0 && group['is_request_status'] !== 1 && group['is_request_status'] !== 2" src="../../../assets/img/selectproduct/deactive.png" (click)="fn_decline_request(group['id'])" id="decline"  class="ml-4" role="button" title="Decline" /> -->
                                <!-- <img  *ngIf="group['is_request_status'] != 1 && group['is_request_status'] != 2" src="../../../assets/img/selectproduct/active.png" (click)="fn_accept_request(group['id'])" id="accept" class="ml-4" role="button" title="Accept" /> -->
                            <!-- </td> -->
                        </tr>
                        </tbody>
                    </table>
                </div>

            <!-- CD Updation Form -->
            <div class="modal"  role="dialog" [ngStyle]="{'display':displayStyleAdd}">
                <div class="modal-dialog" role="document" style="max-width: 800px;margin-top: -50px;">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title"> ADD/Adjust CD BALANCE</h4>
                        </div>
                        <form [formGroup]="AddInfo">
                            <div class="modal-body">                                
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="entity_name">Client Name </label>
                                            <select class="form-select" id="entity_name" formControlName="entity_name" >
                                                <option value="" selected>Select Client Name</option>
                                                <option *ngFor="let res2 of ClientId" [value]="res2.id">{{res2.name}}</option>
                                            </select>
                                            <ng-container
                                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.entity_name.errors">
                                                <p class="error"
                                                    *ngIf="AddInfoSubmitted && getAddInformationFormControls.entity_name.errors.required">
                                                    Entity Name is required
                                                </p>
                                            </ng-container>
                                        </div>
                                    </div>
                
                                    <!-- <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="policy_number">Policy Number </label>
                                            <input type="text" class="form-control" formControlName="policy_number" id="policy_number" placeholder="Policy Number" />
                                        </div>
                                    </div>
                
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="policy_name">Policy Name </label>
                                            <input type="text" class="form-control" formControlName="policy_name" id="policy_name" placeholder="Policy Name" />
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="policy_type">Policy Type</label>
                                            <input type="text" class="form-control" formControlName="policy_type" id="policy_type" placeholder="Policy Type" />
                                        </div>
                                    </div> -->

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="deposit_date">Transaction Date</label>
                                            <input type="date" class="form-control" formControlName="deposit_date" id="deposit_date" [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.deposit_date.errors}"
                                            required />
                                        </div>
                                        <ng-container
                                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.deposit_date.errors">
                                            <p class="error"
                                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.deposit_date.errors.required">
                                                Transaction Date is required
                                            </p>
                                        </ng-container>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="cd_amount">Amount</label>
                                            <input type="text" class="form-control" formControlName="cd_amount" id="cd_amount" placeholder="Amount" (keypress)="isNumber($event)" [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.cd_amount.errors}"
                                            required />
                                        </div>
                                        <ng-container
                                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.cd_amount.errors">
                                            <p class="error"
                                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.cd_amount.errors.required">
                                                Amount is required
                                            </p>
                                        </ng-container>
                                    </div>
                
                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="trans_no">Chq/DD/Trans No. </label>
                                            <input type="text" class="form-control" formControlName="trans_no" id="trans_no" placeholder="Chq/DD/Trans No." [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.trans_no.errors}"
                                            required/>
                                        </div>
                                        <ng-container
                                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.trans_no.errors">
                                            <p class="error"
                                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.trans_no.errors.required">
                                                Chq/DD/Trans No. is required
                                            </p>
                                        </ng-container>
                                    </div>
                                    
                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="bank_name">Bank Name</label>
                                            <input type="text" class="form-control" formControlName="bank_name" id="bank_name" placeholder="Bank Name" [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.bank_name.errors}"
                                            required />
                                        </div>
                                        <ng-container
                                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.bank_name.errors">
                                            <p class="error"
                                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.bank_name.errors.required">
                                                Bank Name is required
                                            </p>
                                        </ng-container>
                                    </div>
                
                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="bank_branch">Bank Branch</label>
                                            <input type="text" class="form-control" formControlName="bank_branch" id="bank_branch" placeholder="Bank Branch" [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.bank_branch.errors}"
                                            required />
                                        </div>
                                        <ng-container
                                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.bank_branch.errors">
                                            <p class="error"
                                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.bank_branch.errors.required">
                                                Bank Branch is required
                                            </p>
                                        </ng-container>
                                    </div>
                
                                    <div class="col-sm-4 mt-3">
                                        <div class="form-group">
                                            <label for="payment_mode">Payment Mode</label>
                                            <input type="text" class="form-control" formControlName="payment_mode" id="payment_mode" placeholder="Payment Mode" [ngClass]="{'control-red': AddInfoSubmitted && getAddInformationFormControls.payment_mode.errors}"
                                            required />
                                        </div>
                                        <ng-container
                                            *ngIf="AddInfoSubmitted && getAddInformationFormControls.payment_mode.errors">
                                            <p class="error"
                                                *ngIf="AddInfoSubmitted && getAddInformationFormControls.payment_mode.errors.required">
                                                Payment Mode is required
                                            </p>
                                        </ng-container>
                                    </div>

                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-success" (click)="onSubmitAdd()" role="button">Save</button>                    
                                <button type="button" class="btn btn-danger" (click)="closePopupAdd()"> Close</button>
                            </div>
                        </form>            
                    </div>
                </div>
              </div>

        </div>
        <app-footer></app-footer>
    </div>

</div>
