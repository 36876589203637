import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { Service } from './service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-endorsement-listing',
  templateUrl: './endorsement-listing.component.html',
  styleUrls: ['./endorsement-listing.component.css']
})
export class EndorsementListingComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;


  addflag: any = 0;

  show = false;
  fullScreen = true;
  template = ``;

  endo_document: any;
  endo_documentDownloadFile: any;
  endo_documentDownloadFileName: any;


  ClientId:any = [];
  clientwisepolicy :any = [];
  InfoArray:any = [];
  InfoPolicy:any = [];
  InfoArraydropdown: any = [];
  endorsement_status_broker: any = [];
  statusVal: any;

  displayStyleEdit = "none";
  displayStyleView = "none";
  displayStyleUpload = "none";

  selectedPolicyId: any;
  document_file: any;
  endofile: any;
  endofileName: any;
  endoId: any;

  user_role: any;
  validmsg: any;
  resstatus = localStorage.getItem('resstatus');
  type_name = localStorage.getItem('type_name');
  username = localStorage.getItem('username');

  public AddInfo:FormGroup;
  AddInfoSubmitted: boolean = false;

  public EditInfo:FormGroup;
  EditInfoSubmitted: boolean = false;

  dtOptions: DataTables.Settings = {
    destroy: true,
    scrollX: true,
    columnDefs: [
      { orderable: true, className: 'reorder', targets: 0 },
      { orderable: false, targets: '_all' }
    ]
  };
  dtTrigger: Subject<any> = new Subject<any>();

  readaccess   : any = 1;
  writeaccess  : any = 1;
  deleteaccess :  any = 1;
  modulename = 'claim_executive_dashboard';

  clientname: any;
  clientid: any;


  constructor(private fb: FormBuilder, private dataservice: Service, private route: Router) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.user_role = localStorage.getItem('type_name');
        this.clientname = localStorage.getItem('clientname');
        // this.clientid = localStorage.getItem('clientid');

      }
      if (this.resstatus == null) {
        this.route.navigate(['/home']);
      }
    });
  }

  ngOnInit(): void {

    this.initGroup();

  }

  initGroup(){

    this.InfoArray = [];
    this.getInfo('', 'default');
    this.getInfodropdown();

    this.validmsg = '';
    $('#validmsg').css('display', 'none');
    // this.getInfo();
    this.validmsg = '';
    $('#validmsg').css('display', 'none');

    this.EditInfo = this.fb.group({
      client_id: new FormControl('', Validators.required),
      policy_number: new FormControl(''),
      endo_date: new FormControl(''),
      new_member_count: new FormControl(''),
      individualCoverAmount: new FormControl(''),
      totalSI: new FormControl(''),
      permili_rate: new FormControl('', Validators.required),
      gst: new FormControl(''),
      totalPremiumwithGST: new FormControl(''),
      totalPremium: new FormControl(''),
      prorataPremium: new FormControl(''),
      coverageDays: new FormControl(''),
      gst_amount: new FormControl(''),

      endo_status: new FormControl('', Validators.required),
      endo_copy: new FormControl(''),
      insurer_endo_no: new FormControl('', Validators.required),

      edit_id: new FormControl(''),
    });

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  getInfodropdown(){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id": localStorage.getItem('broker_id')
    };

    this.dataservice.getInfodropdown(postdata)
        .subscribe((result: any) => {

          this.show = false;
          // console.log(result);
          this.ClientId = result.data;

        }, (error) => {

          this.ClientId = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

      });
  }

  getInfo(val:any, value:any){

    this.show = true;
    this.fullScreen = true;
    this.template = ``;
    this.InfoArray = [];
    var postdata:any;

    if(value == 'default'){
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "employer_id": 0,
        "broker_id": localStorage.getItem('broker_id')
      };
    }else{
      postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        // "employer_id":localStorage.getItem('employer_id'),
        "employer_id": val.target.value,
        "broker_id": localStorage.getItem('broker_id')
      };
    }

    this.dataservice.getInfo(postdata)
        .subscribe((result: any) => {

          this.show = false;
          // console.log(result);
          this.InfoArray = result.data;
          let arr = result.data;
          this.dtOptions = {
            destroy: true,
            scrollX: true,
            columnDefs: [
              { orderable: true, className: 'reorder', targets: 0 },
              { orderable: false, targets: '_all' }
            ]
          };
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear();
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });

        }, (error) => {

          this.show = false;

          this.InfoArray = [];

          if(error.error.message == 'Unauthenticated.'){
            localStorage.clear();
            localStorage.setItem('resstatus', 'false');
            this.route.navigate(['/login']);
          }

      });
  }

  get getEditInformationFormControls() {
    return this.EditInfo.controls;
  }

  editpolicy(res :any){
    this.displayStyleEdit = "block";

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "endorsement_id":res.id
    };

    this.dataservice.InfoSingle(postdata)
    .subscribe((result: any) => {

      // console.log(result[0]['username']);

      this.EditInfo.controls['client_id'].setValue(result.data.endorsement_info[0]['employer_name']);
      this.EditInfo.controls['policy_number'].setValue(result.data.endorsement_info[0]['policy_number']);
      this.EditInfo.controls['endo_date'].setValue(result.data.endorsement_info[0]['endorsement_date']);
      this.EditInfo.controls['new_member_count'].setValue(result.data.endorsement_info[0]['member_count']);
      this.EditInfo.controls['individualCoverAmount'].setValue(result.data.endorsement_info[0]['individual_cover']);
      this.EditInfo.controls['totalSI'].setValue(result.data.endorsement_info[0]['total_sum_insured']);
      this.EditInfo.controls['permili_rate'].setValue(result.data.endorsement_info[0]['premium_rate_per_milli']);
      this.EditInfo.controls['totalPremium'].setValue(result.data.endorsement_info[0]['total_premium']);
      this.EditInfo.controls['prorataPremium'].setValue(result.data.endorsement_info[0]['prorata_premium']);
      this.EditInfo.controls['coverageDays'].setValue(result.data.endorsement_info[0]['coverage_days']);
      this.EditInfo.controls['gst'].setValue(result.data.endorsement_info[0]['gst_percantage']);
      this.EditInfo.controls['gst_amount'].setValue(result.data.endorsement_info[0]['gst_amount']);
      this.EditInfo.controls['totalPremiumwithGST'].setValue(result.data.endorsement_info[0]['total_premium_with_gst']);
      this.EditInfo.controls['insurer_endo_no'].setValue(result.data.endorsement_info[0]['insurer_endo_number']);
      this.EditInfo.controls['endo_status'].setValue(result.data.endorsement_info[0]['current_status_id']+'@'+result.data.endorsement_info[0]['current_status']);

      this.endorsement_status_broker = result.data.endorsement_status_broker;

      this.EditInfo.controls['edit_id'].setValue(result.data.endorsement_info[0]['id']);

    }, (error) => {

  });

  }

  closePopup() {

    this.displayStyleEdit = "none";
    this.displayStyleView = "none";
    this.displayStyleUpload = "none";
  }

  onSubmitEdit(){

    let id = $('#edit_id').val();

    this.EditInfoSubmitted = true;
    if (this.EditInfoSubmitted && this.EditInfo.valid) {

      let endoStatus:any = this.EditInfo.controls['endo_status'].value;
      if(endoStatus == '3@Finalized by Broker'){
        if(this.endo_document == "" || this.endo_document == undefined || this.endo_document == null || this.endo_document == 0){
          $('#endofile_error').css('display', 'block');
          $('#features-file-upload_document_file').css('border', '1px solid red');
          return;
        }else{
          $('#endofile_error').css('display', 'none');
          $('#features-file-upload_document_file').css('border', '1px solid #dee2e6');
        }
      }

      this.show = true;
      this.fullScreen = true;
      this.template = ``;

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "endorsement_id": id,
        "broker_id":localStorage.getItem('broker_id'),

        "status_name": this.EditInfo.controls['endo_status'].value,
        "insurer_endo_no":this.EditInfo.controls['insurer_endo_no'].value,
        "endo_copy": this.endo_document,

      };

      // console.log(postdata);

      this.show = false;

      this.dataservice.InfoUpdate(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {

          if (result.status == true) {

            this.show = false;
            // console.log(result.data);

            $('#validmsg1').css('display', 'block');
            $('#errormsg1').css('display', 'none');
            
            this.validmsg = result.message;
            // this.getInfo();
            // this.dtTrigger.unsubscribe();
            setTimeout(() => {
              this.closePopup();

              window.location.reload();
            }, 3000);


          }else {
              this.show = false;
          }
        }, (error) => {
          this.closePopup();
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
      });

    }

  }

  endofile_document(event: any){

    if(event.target.files.length > 0){
      this.endo_document = event.target.files[0];
      $("#features-file-upload_document_file").empty();
      $('#features-file-upload_document_file').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);
      // console.log(iSize);
      iSize = (Math.round(iSize * 100) / 100);

      $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

    }
  }

  ondownload_uploaded(fileUrl: string, fileName: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  viewpolicy(res :any){
    this.displayStyleView = "block";

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "endorsement_id":res.id
    };

    this.dataservice.InfoSingle(postdata)
    .subscribe((result: any) => {

      // console.log(result[0]['username']);

      this.EditInfo.controls['client_id'].setValue(result.data.endorsement_info[0]['employer_name']);
      this.EditInfo.controls['policy_number'].setValue(result.data.endorsement_info[0]['policy_number']);
      this.EditInfo.controls['endo_date'].setValue(result.data.endorsement_info[0]['endorsement_date']);
      this.EditInfo.controls['new_member_count'].setValue(result.data.endorsement_info[0]['member_count']);
      this.EditInfo.controls['individualCoverAmount'].setValue(result.data.endorsement_info[0]['individual_cover']);
      this.EditInfo.controls['totalSI'].setValue(result.data.endorsement_info[0]['total_sum_insured']);
      this.EditInfo.controls['permili_rate'].setValue(result.data.endorsement_info[0]['premium_rate_per_milli']);
      this.EditInfo.controls['totalPremium'].setValue(result.data.endorsement_info[0]['total_premium']);
      this.EditInfo.controls['gst'].setValue(result.data.endorsement_info[0]['gst_percantage']);
      this.EditInfo.controls['prorataPremium'].setValue(result.data.endorsement_info[0]['prorata_premium']);
      this.EditInfo.controls['coverageDays'].setValue(result.data.endorsement_info[0]['coverage_days']);
      this.EditInfo.controls['gst_amount'].setValue(result.data.endorsement_info[0]['gst_amount']);
      this.EditInfo.controls['totalPremiumwithGST'].setValue(result.data.endorsement_info[0]['total_premium_with_gst']);
      this.EditInfo.controls['insurer_endo_no'].setValue(result.data.endorsement_info[0]['insurer_endo_number']);
      this.EditInfo.controls['endo_status'].setValue(result.data.endorsement_info[0]['current_status_id']+'@'+result.data.endorsement_info[0]['current_status']);

      this.statusVal = result.data.endorsement_info[0]['current_status_id'];

      this.endorsement_status_broker = result.data.endorsement_status_broker;

      this.EditInfo.controls['edit_id'].setValue(result.data.endorsement_info[0]['id']);

    }, (error) => {

  });

  }

  uploadEndo(id :any){
    this.displayStyleUpload = "block";
    this.endoId = id;

    var postdata = {
      "tokenable_id":localStorage.getItem('tokenable_id'),
      "broker_id":localStorage.getItem('broker_id'),
      "endorsement_id":this.endoId
    };
    this.dataservice.download_EndoFile(postdata)
    .subscribe((result) => {

      this.endofile = result.data['endorsement_broker_sample'];
      this.endofileName = result.data['filename'];

    }, (error) => {
        // console.log(result);
  });

  }

  endorsement_doc(event: any){

    if(event.target.files.length > 0){
      this.document_file = event.target.files[0];
      $("#features-file-upload_document_file_endo").empty();
      $('#features-file-upload_document_file_endo').html(event.target.files[0]['name']);
      $('#features-file-upload_document_file_endo').css('text-align', 'left');

      var iSize = Math.round(event.target.files[0]['size'] / 1024);

      // iSize = (Math.round(iSize * 100) / 100);

      // $('#features-file-upload_document_file').append('<img src="../../assets/img/policy/file.png" style="float:left; width:12px; margin-top: 2px; margin-right:5px" /> <span style="float: right">'+iSize+'kb</span>');

      // $('.sizeerror').css('display', 'none');
      $('#submitbtn').css('pointer-events', 'auto');
      $('#submitbtn').css('background', 'var(--bs-btn-bg)');
      $('#submitbtn').css('border-color', 'var(--bs-btn-border)');

    }
  }


  ondownload(fileUrl: string, fileName: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onsubmit_EndoFile(){

    var document_name = $('#document').val();

    if(this.document_file == "" || this.document_file == null || this.document_file == undefined || document_name == ""){

      if(this.document_file == "" || this.document_file == null || this.document_file == undefined){
        $('#document_file_error').css('display', 'block');
        $('#features-file-upload_document_file').css('border', 'solid 1px red');
      }else{
        $('#document_file_error').css('display', 'none');
        $('#features-file-upload_document_file').css('border', '1px solid #ced4da;');
      }

      if(document_name == ""){
        $('#document_error').css('display', 'block');
        $('#document').css('border', 'solid 1px red');
      }else{
        $('#document_error').css('display', 'none');
        $('#document').css('border', '1px solid #ced4da;');
      }

    }else{

      var postdata = {
        "tokenable_id":localStorage.getItem('tokenable_id'),
        "broker_id": localStorage.getItem('broker_id'),
        "endorsement_id": this.endoId,
        "document_file": this.document_file
      }

      console.log(postdata);

      this.dataservice.UploadEndoCopy(postdata, localStorage.getItem('Token'))
        .subscribe((result: any) => {
          console.log(result);
          if (result.status == true) {
            // console.log(result);
            // window.location.reload();

            $('#validmsg').css('display', 'block');
            $('#errormsg').css('display', 'none');
            setTimeout(() => {
              window.location.reload();
            }, 3000);

          } else {
            // console.log(result);
          }
        }, (error) => {
          this.validmsg = error.error.message;
          $('#validmsg').css('display', 'none');
          $('#errormsg').css('display', 'block');
          $("#errormsg").css('display', 'block');

      });

    }

  }

  closealert(){
    $("#validmsg").css('display', 'none');
    $("#errormsg").css('display', 'none');
  }

  downloadCSV() {    

    var postdata = {
      "tokenable_id": localStorage.getItem('tokenable_id'), 
      "broker_id":localStorage.getItem('broker_id'),   
      "employer_id": $('#client_name').val(),    
    }

    this.dataservice.download(postdata, localStorage.getItem('Token'))
      .subscribe((result: any) => {

        let finalArr:any = result.data;
    
        console.log(finalArr);    
    
        /* pass here the table id */
        let element = document.getElementById('excel-table');
        const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(finalArr);
    
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
        /* save to file */  
        XLSX.writeFile(wb, 'Broker_Endorsement_Data.xlsx');

      }, (error) => {    

        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          localStorage.setItem('resstatus', 'false');
          this.route.navigate(['/home']);
        }

      });    

  }

}
