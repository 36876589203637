<!-- Body -->
<div class="container-fluid page-body-wrapper">

    <!-- Sidebar -->
    <app-sidebar></app-sidebar>

    <!-- Main Wrapper -->
    <div class="main-panel">
        <div class="content-wrapper pt-3">

            <h3 class="bs-title">CD Summary</h3>
            <hr class="mb-2">

            <div class="row mt-4">

                <div class="col-sm-12 mb-3">
                    <div class="cardcustom">

                        <!-- Listing -->
                        <div>
                            <table class="table table-sm row-border" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                <thead>
                                    <tr>
                                        <th >Sr No</th>
                                        <th >Upload Type</th>
                                        <th >Policy Name</th>
                                        <th >Policy Number</th>
                                        <th >Transaction Type</th>
                                        <th >Transaction Date</th>
                                        <th >System Generated Endorsement No.</th>
                                        <th >Opening CD balance</th>
                                        <th >Utilized Amount</th>
                                        <th >Closing CD balance</th>
                                        <th >Insurer Endorsement copy / Policy Copy</th>
                                        <th >Uploaded At</th>
                                        <!-- <th class="w15">Action</th> -->
                                        </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let group of InfoPolicy; let i = index">
                                        <td style="width:5%">{{i+1}}</td>
                                        <td>{{group['upload_type']}}</td>
                                        <td>{{group['policy_name']}}</td>
                                        <td>{{group['policy_number']}}</td>
                                        <td>{{group['transaction_type']}}</td>
                                        <td>{{group['transaction_date']}}</td>
                                        <td>{{group['system_generated_endorsement_number']}}</td>
                                        <td>{{group['opening_cd_balance']}}</td>
                                        <td>{{group['utilized_amount']}}</td>
                                        <td>{{group['closing_cd_balance']}}</td>
                                        <td *ngIf="group['insurer_endorsement_copy']==='' && group['insurer_endorsement_copy']===null"><span>NA</span></td>
                                        <td style="width: 15%" align="center">
                                            <img *ngIf="group['insurer_endorsement_copy']!=='' && group['insurer_endorsement_copy']!== null" src="../../../assets/img/common icons/downloaddoc.png" id="download" (click)="ondownload_uploaded(group['insurer_endorsement_copy'], group['insurer_endorsement_copy'])" class="ml-4" role="button" title="Download" />
                                        </td>
                                        <td>{{group['added_date']}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>

        </div>
        <app-footer></app-footer>
    </div>

  </div>
