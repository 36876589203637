import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClientCreationService {

  AUTH_API = environment.apiURL;

  constructor(private http: HttpClient) { }
  
  onSubmitFirst(data: any, token: any): Observable<any> {
    var URL;
    if(data.claim_id == 0){
      URL = 'claimpolicy/step1-store';
    }else{
      URL = 'claimpolicy/step1-update/' + data.claim_id;
    }

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + URL,
      data
    , httpOptions);  
  }

  onSubmitSecond(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/step2-update/' + data.claim_id,
      data
    , httpOptions);
  }


  onSubmitEditFirst(data: any, token: any): Observable<any> {
    var URL;
    if(data.claim_id == 0){
      URL = 'claimpolicy/step1-store';
    }else{
      URL = 'claimpolicy/step1-update/' + data.claim_id;
    }

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + URL,
      data
    , httpOptions);  
  }

  onSubmitEditSecond(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/step2-update/' + data.claim_id,
      data
    , httpOptions);
  }

  /********** Get API **********/

  getInfo(data: any, token: any): Observable<any> {    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/claimlistbyclientinsurertypepolicy', 
    data,
    httpOptions);    
  }

  AddInfo(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/store', 
      data
    , httpOptions);
  }  

  InfoDelete(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/deleteclaimrecord/' + data.claim_id,
      data
    , httpOptions);
  }
 
  InfoSingle(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/showclaiminfobyclaimid/' + data.id, 
      data
    , httpOptions);
  }

  InfoUpdate(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/update/' + data.id,
      data
    , httpOptions);
  }

  getInfoRelation(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'claimpolicy-relationcodelist',       
     httpOptions);
  }

  getpolicyno(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/unnamedpolicylist', 
      data
    , httpOptions);
  }
  
  getPeriod(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/periodbypolicyid/' + data.policy_id, 
      data
    , httpOptions);
  }

  getSumAssured(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/sumassuredbypolicyid/' + data.policy_id, 
      data
    , httpOptions);
  }

  gettypeclaim(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.get(this.AUTH_API + 'claimpolicy-typeofclaimlist',       
    httpOptions);
  }

  // dropdown 1  
  getInfoClient(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/clientlistbybrokerid/' + data.broker_id, 
      data,
      httpOptions);
  }

  // dropdown 2   
  getInfoInsurer(data: any, token: any): Observable<any> {    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/insurerlistbyclientid/' + data.client_id,
      data
    , httpOptions);  
  }

  // dropdown 3    
  getInfoProductType(data: any, token: any): Observable<any> {    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/producttypelistbyclientid/' + data.tokenable_id,
      data
    , httpOptions);  
  }  

  // dropdown 4
  getInfoMasterPolicyNo(data: any, token: any): Observable<any> {    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+token})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/policylistbyclientinsurertype/' + data.tokenable_id,
      data
    , httpOptions);  
  }

  getcountry(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ localStorage.getItem('Token')})
    };
    return this.http.post(this.AUTH_API + 'city/citydetails',
      data
    , httpOptions);
  }

  getInfoStatus(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ token})
    };
    return this.http.post(this.AUTH_API + 'claimstatus/statuslistbypolicyid/'+ data.policy_id,
      data
    , httpOptions);
  }

  onSubmitStatus(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ token})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/updatestatusbyclaimid/'+ data.claim_id,
      data
    , httpOptions);
  }

  onSubmitClaimNumber(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ token})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy/updateclaimnumberbyclaimid/'+ data.claim_id,
      data
    , httpOptions);
  }
  download(data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': 'Bearer '+ token})
    };
    return this.http.post(this.AUTH_API + 'claimpolicy-download',
      data
    , httpOptions);
  }
}